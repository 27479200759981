<template>
    <div>
      <div class="ml-2 mr-2">
        <h5>{{ $t("general.stepForOkrYearEndReview") }}</h5>
        <b-card-text>
          <p v-html="getTranslate(info, 'yearEndReview', $i18n.locale)"></p>
        </b-card-text>
      </div>
  
      <div class="p-2">
        <validation-observer ref="createForm" #default="{ invalid }">
          <n-form-confirmation
            key="createForm"
            :form="$refs.createForm"
            :disabled="invalid"
          >
            <n-input-view :fields="fields" v-model="data" :initValue="initData">
              <template #objectives>
                <b-tabs pills class="tab-card mb-2" v-model="tabIndex">
                  <b-tab
                    v-for="(type, index) in objectiveTypes"
                    :key="`${type}-${index}`"
                    @click="getCurrentTab(type)"
                  >
                    <template #title>
                      <span>
                        {{ getTranslate(type, "title", $i18n.locale) }}
                      </span>
                    </template>
                    <template>
                      <b-card-text
                        class="mb-2 mt-2"
                        v-if="objectiveType.descriptionEn"
                      >
                        <h5>
                          {{
                            getTranslate(
                              objectiveType,
                              "description",
                              $i18n.locale
                            )
                          }}
                        </h5>
                      </b-card-text>
                      <p v-else class="mt-3"></p>
                      <okr-setting-header v-if="tabIndex == 0 || tabIndex == 1" />
                      <app-collapse accordion type="margin">
                        <app-collapse-item
                          :is-visible="true"
                          :title="`${item}-${index}`"
                          v-for="(item, index) in objectives.filter(
                            (item) => item.objectiveTypeId === objectiveType.id
                          )"
                          :key="`item-${item}-${index}`"
                          class="mb-1 mt-1"
                          :class="{ 'collapse-item-bg': index % 2 != 0 }"
                        >
                          <template #header>
                            <okr-setting-question
                              v-if="item.objectiveTypeId == 1"
                              :no="index + 1"
                              :question="item.title"
                              :weight="item.weight"
                            >
                            </okr-setting-question>
                            <okr-setting-question
                              v-else
                              :no="index + 1"
                              :weight="item.weight"
                            >
                              <template #question>
                                <div>
                                  <h6>
                                    {{
                                      getTranslate(
                                        item.objective,
                                        "description",
                                        $i18n.locale
                                      )
                                    }}
                                  </h6>
                                  <p>
                                    {{
                                      getTranslate(
                                        item.objective,
                                        "title",
                                        $i18n.locale
                                      )
                                    }}
                                  </p>
                                </div>
                              </template>
                            </okr-setting-question>
                          </template>
                          <okr-setting-answer>
                            <template #keyresult>
                              <div
                                v-for="(result, idx) in item.objectiveKeyResults"
                                :key="`${result}-${idx}`"
                                :value="result.title"
                              >
                                <div v-if="result.title != null">
                                  <hr/>
                                  <b-row>
                                    <b-col>
                                      <label class="text-muted ml-2"
                                        >{{ $t('field.keyResult') }}
                                      </label>
                                      <b-input-group class="shadow-none">
                                        {{ idx + 1 }}.
                                        <b-form-textarea
                                          disabled
                                          v-model="result.title"
                                          class="mb-1 ml-1"
                                          autocomplete="off"
                                        />
                                      </b-input-group>
                                    </b-col>
                                    <b-col cols="2">
                                      <label class="text-muted">{{
                                        $t("field.yearEndResultStatus")
                                      }}</label>
                                      <div>
                                        <b-badge
                                        v-if="result.statusNameEn != null"
                                        pill
                                        :variant="
                                          getYearEndStateColor(
                                            result.statusNameEn
                                          )
                                        "
                                        badge-glow
                                      >
                                        {{
                                          getTranslate(
                                            result,
                                            "statusName",
                                            $i18n.locale
                                          )
                                        }}
                                      </b-badge>
                                      </div>
                                    </b-col>
                                  </b-row>
                                </div>
                              </div>
                              <hr class="mt-2 mb-2" />
                              <div>
                                <h5>
                                  {{ $t("field.employeeAssessment") }}
                                </h5>
                                <br />
                                <b-row>
                                  <b-col cols="3">
                                    <label>{{ $t(`field.selfRating`,{min:'(20%-100%)'}) }}</label>
                                    <b-input-group
                                      class="mb-1"
                                      size="md"
                                      append="%"
                                    >
                                      <b-form-input
                                        disabled
                                        type="number"
                                        v-model="item.rating"
                                        autocomplete="off"
                                      />
                                    </b-input-group>
                                
                                  </b-col>
                                  <b-col cols="3">
                                    <label>{{
                                      $t(`field.directSupervisorRating`,{min:'(20%-100%)'})
                                    }}</label>
                                    <b-input-group
                                      class="mb-1"
                                      size="md"
                                      append="%"
                                    >
                                      <b-form-input
                                        disabled
                                        v-model="item.supervisorRating"
                                        type="number"
                                        autocomplete="off"
                                      />
                                    </b-input-group>
                                  </b-col>
                                  <b-col cols="12">
                                    <label>{{
                                      $t("field.directSupervisorComment")
                                    }}</label>
                                    <b-form-textarea
                                      disabled
                                      v-model="item.supervisorComment"
                                      rows="3"
                                      max-rows="3"
                                      placeholder=""
                                    ></b-form-textarea>
                                  </b-col>
                                </b-row>
                                <br />
                                <div class="text-white p-1 rounded weigt-score">
                                  <b-row>
                                    <b-col
                                      ><h5 class="text-primary">
                                        {{ $t("field.weightScore") }}
                                      </h5></b-col
                                    >
                                    <b-col class="text-right">
                                      <h5 class="text-primary">
                                        {{ calculateWeightScore(item) }} %
                                      </h5>
                                    </b-col>
                                  </b-row>
                                </div>
                              </div>
                            </template>
                          </okr-setting-answer>
                        </app-collapse-item>
                      </app-collapse>
                      <okr-setting-footer
                        v-if="tabIndex == 0 || tabIndex == 1"
                        :total-weight="calculateWeight(objectiveType.id)"
                      />
                    </template>
                  </b-tab>
                </b-tabs>
              </template>
            </n-input-view>
            <!-- Comment -->
            <div
              v-show="objectiveType.type == 'comment'"
              class="shadow-sm p-2 mb-2 bg-white rounded"
            >
              <div
                v-for="(commentQuestion, idx) in comments"
                :key="`${commentQuestion}-${idx}`"
              >
                <h5>
                  {{ idx + 1 }}.
                  {{ getTranslate(commentQuestion, "title", $i18n.locale) }}
                </h5>
                <b-form-textarea
                  :disabled="true"
                  v-model="commentQuestion.comment"
                  class="mb-3 mt-2"
                  rows="3"
                />
              </div>
            </div>
            <!-- Consideration -->
            <div
              v-show="objectiveType.type == 'consideration'"
              class="shadow-sm p-2 mb-2 bg-white rounded"
            >
              <div
                v-for="(considerQuestion, idx) in considers"
                :key="`${considerQuestion}-${idx}`"
              >
                <h5>
                  {{ idx + 1 }}.
                  {{ getTranslate(considerQuestion, "title", $i18n.locale) }}
                </h5>
                <b-form-textarea
                  :disabled="true"
                  v-model="considerQuestion.comment"
                  class="mb-3 mt-2"
                  rows="3"
                />
              </div>
            </div>
            <!-- Management Comment -->
            <div
              v-show="objectiveType.type == 'managementcomment'"
              class="shadow-sm p-2 mb-2 bg-white rounded"
            >
              <div class="py-2">
                <h5>1. {{ $t('field.hodComment') }}</h5>
                <b-form-textarea
                  :disabled="true"
                  v-model="initData.hodComment"
                  rows="3"
                />
              </div>
              <div class="py-2">
                <h5>2. {{ $t("field.gmComment") }}</h5>
                <b-form-textarea
                  :disabled="true"
                  v-model="initData.gmComment"
                  rows="3"
                />
              </div>
              <div class="py-2">
              <h5>3. {{ $t("field.hrComment") }}</h5>
              <b-form-textarea
                :disabled="true"
                v-model="initData.hrComment"
                rows="3"
              />
            </div>
            </div>
            <!-- Signature -->
            <div
              v-show="objectiveType.type == 'signature'"
              class="shadow-sm p-2 mb-2 bg-white rounded"
            >
              <b-row
                v-for="(label, idx) in signatures"
                :key="`${label.id}-${idx}`"
                class="mb-3"
                align-v="center"
              >
                <b-col>
                  <h5>
                    {{ getTranslate(label, "name", $i18n.locale) }}
                  </h5>
                </b-col>
                <b-col>
                  <n-async-upload-signature
                    path="year-end-signature"
                    ph="41"
                    pw="220"
                    v-if="label.name != ''"
                    :isDisable="true"
                    :disableUploadHistory="true"
                    :disableSpacer="false"
                    :fullWidth="false"
                    :image="label.image"
                    v-model="label.imageId"
                    @change="onUploadChange($event, label.id)"
                  ></n-async-upload-signature>
                  <p v-else>
                    ........................................................
                  </p>
                </b-col>
                <b-col cols="2">
                  <h5 v-if="label.name != null">{{ label.name }}</h5>
                  <p v-else>
                    ........................................................
                  </p>
                </b-col>
                <b-col cols="4">
                  <p>
                    {{ $t("field.date") }}
                    <span v-if="label.date != null">
                      {{ formatDate(label.date) }}</span
                    ><span v-else>............................</span>
                  </p>
                </b-col>
              </b-row>
              <hr />
              <p>
                {{ $t("field.signatureDescription") }}
              </p>
            </div>
            <!-- Display total rating  -->
            <div>
              <div
                v-show="tabIndex == 0 || tabIndex == 1"
                class="card-container mt-2"
              >
                <b-row class="w-100">
                  <b-col cols="10">
                    <h6 class="pb-1">{{ $t("field.totalSelfRating") }}</h6>
                    <h6>{{ $t("field.overallObjectiveScore") }}</h6>
                  </b-col>
                  <b-col cols="2" class="text-center">
                    <h4 class="text-primary pb-1 pr-3">
                      {{ convertToDouble(calculateSelfRating(objectiveType.id)) }}%
                    </h4>
                    <h4 class="text-primary pr-3">
                      {{ convertToDouble(calculateOverallObjevtiveScore(objectiveType.id)) }}%
                    </h4>
                  </b-col>
                </b-row>
              </div>
              <b-card-text v-show="tabIndex == 1" class="mt-1" title="">
                <div class="m-0 card-headline rounded">
                  <b-row align-v="center">
                    <b-col>
                      <h4 class="text-center">
                        {{ $t("breadcrumb.overallYearEndRating") }}
                      </h4>
                    </b-col>
                    <b-col class="text-center">
                      <div
                        class="p-1 rounded"
                        :class="
                          getOverAllYearEndStatus(calculateOverallYearEndRating())
                            .class
                        "
                      >
                        <h4 class="text-center text-white">
                          {{ convertToDouble(calculateOverallYearEndRating()) }} %
                        </h4>
                        <hr class="bg-dark" />
                        <h4 class="text-center text-white capitalize">
                          {{
                            getOverAllYearEndStatus( calculateOverallYearEndRating() ).name
                          }}
                        </h4>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-card-text>
            </div>
  
            <div class="w-100 text-right">
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </div>
          </n-form-confirmation>
        </validation-observer>
      </div>
    </div>
  </template>
              
              <script>
  import {
    BCol,
    BRow,
    BCard,
    BFormRadioGroup,
    BFormRadio,
    BFormGroup,
    BCardText,
    BFormInput,
    BButton,
    BFormTextarea,
    BInputGroup,
    BTab,
    BTabs,
    BBadge,
    BSpinner,
    BModal,
    BImgLazy,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import BCardCode from "@core/components/b-card-code";
  import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
  import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
  import Repository from "@/repositories/RepositoryFactory";
  import {
    getTranslate,
    getYearEndStateColor,
    convertToDouble,
    getOverAllYearEndStatus,
    State,
  } from "@/libs/helper";
  import OkrSettingQuestion from "@/components/OkrSettingQuestion.vue";
  import OkrSettingAnswer from "@/components/OkrSettingAnswer.vue";
  import NButtonLoading from "@/components/NButtonLoading";
  import NFormConfirmation from "@/components/NFormConfirmation";
  import NInput from "@/components/NInput";
  import NInputView from "@/components/NInputView";
  import NButtonApprove from "@/components/NButtonApprove.vue";
  import OkrSettingHeader from "@/components/OkrSettingHeader.vue";
  import OkrSettingFooter from "@/components/OkrSettingFooter.vue";
  import NButtonReject from "@/components/NButtonReject.vue";
  import ProfileInformation from "@/components/ProfileInformation.vue";
  import FormInput from "./formInput";
  import vSelect from "vue-select";
  import NAsyncUploadSignature from "@/components/NAsyncUploadSignature.vue";
  import Swal from "sweetalert2";
  import { formatDate } from "@/@core/utils/filter";
  const ObjectiveTypeRepository = Repository.get("objectiveType");
  const StepInformationRepository = Repository.get("stepInformation");
  const YearEndReviewRepository = Repository.get("yearEndReview");
  export default {
    components: {
      BFormInput,
      BCard,
      BCol,
      BRow,
      BFormRadioGroup,
      BFormRadio,
      BCardText,
      BFormGroup,
      BButton,
      BFormTextarea,
      BInputGroup,
      BTabs,
      BTab,
      BBadge,
      BSpinner,
      BModal,
      BImgLazy,
      BCardCode,
      AppCollapse,
      AppCollapseItem,
      OkrSettingAnswer,
      OkrSettingQuestion,
      NInput,
      NButtonLoading,
      NFormConfirmation,
      NButtonApprove,
      OkrSettingHeader,
      OkrSettingFooter,
      NButtonReject,
      NInputView,
      vSelect,
      NAsyncUploadSignature,
      ProfileInformation,
    },
    directives: {
      Ripple,
    },
    props: {
      okrSetting: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        tabIndex: 0,
        info: {},
        key: 1,
        data: {
          okrSettingId: null,
          totalRating: null,
          totalWeightScore: null,
          overallRating: null,
          overall: null,
          state: null,
          objectives: [],
          comments: [],
          considers: [],
          signatures: [],
          hodComment: null,
          gmComment: null,
          hrComment: null,
          managementComment: null,
        },
        initData: {},
        objectiveType: {},
        objectiveTypes: [],
        objectives: [],
        statuses: [],
        comments: [],
        considers: [],
        signatures: [],
        overall: null,
        totalWeight: 0,
        loading: false,
        query: {
          page: Number(this.$route.query.page) || 1,
          order: "sequence_order",
          sort: "ASC",
        },
        getTranslate,
        getYearEndStateColor,
        convertToDouble,
        getOverAllYearEndStatus,
        formatDate,
        State,
      };
    },
    created() {
      this.show();
      this.getStepInformation();
      this.getObjectiveType();
    },
    methods: {
      getStepInformation() {
        this.loading = true;
        StepInformationRepository.show()
          .then((response) => {
            const data = response?.data?.data;
            if (data) {
              this.info = data;
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      getCurrentTab(objectiveType) {
        this.objectiveType = objectiveType;
      },
  
      getObjectiveType() {
        this.loading = true;
        const params = {
          ...this.query,
        };
        ObjectiveTypeRepository.index(params)
          .then((response) => {
            const data = response?.data?.data;
            if (data) {
              this.objectiveTypes = [...data.list];
              this.objectiveTypes.push(
                {
                  id: null,
                  type: "comment",
                  titleEn: "Direct Supervisor's Comment",
                  titleKm: "មតិយោបល់របស់អ្នកគ្រប់គ្រងផ្ទាល់​",
                },
                {
                  id: null,
                  type: "consideration",
                  titleEn: "Direct Supervisor's Consideration",
                  titleKm: "ការពិចារណារបស់អ្នកគ្រប់គ្រងផ្ទាល់",
                },
                {
                  id: null,
                  type: "managementcomment",
                  titleEn: "Management's Comment",
                  titleKm: "មតិយោបល់របស់អ្នកគ្រប់គ្រង​",
                },
                {
                  id: null,
                  type: "signature",
                  titleEn: "Signature",
                  titleKm: "ហត្ថលេខា",
                }
              );
              if (this.objectiveTypes.length > 0) {
                this.objectiveType = this.objectiveTypes[0];
              }
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
  
      calculateWeight(objectiveTypeId) {
        var total = 0;
        this.objectives.forEach((item) => {
          if (item.objectiveTypeId == objectiveTypeId) {
            total += Number(item.weight);
          }
        });
  
        return total;
      },
  
      calculateSelfRating(objectiveTypeId) {
        var total = 0;
        this.objectives.forEach((item) => {
          if (item.objectiveTypeId == objectiveTypeId) {
            total += Number(item.rating);
          }
        });
        return total;
      },
      totalSelfRating() {
        var total = 0;
        this.objectives.forEach((item) => {
          total += Number(item.rating);
        });
        return total;
      },
      calculateOverallObjevtiveScore(objectiveTypeId) {
        var total = 0;
        this.objectives.forEach((item) => {
          if (item.objectiveTypeId == objectiveTypeId) {
            total += Number(item.weightScore);
          }
        });
        return total;
      },
  
      calculateWeightScore(objective) {
        var score = 0;
        this.objectives.forEach((item) => {
          if (item.objectiveId == objective.objectiveId) {
            score =
              Number(item.weight / 100) * Number(item.supervisorRating / 100);
            item.weightScore = convertToDouble(score * 100);
          }
        });
        return convertToDouble(score * 100);
      },
  
      calculateOverallYearEndRating() {
        var total = 0;
        this.objectives.forEach((item) => {
          total += Number(item.weightScore);
        });
        return total;
      },
  
      show() {
        this.loading = true;
        const params = `${this.okrSetting.yearEndReview.id}`;
        YearEndReviewRepository.show(params)
          .then((response) => {
            const data = response?.data?.data;
            if (data) {
              this.initData = {
                ...data,
              };
              this.objectives = this.initData.objectives;
              this.signatures = this.initData.signatures;
              this.comments = [...this.initData.comments];
              this.considers = [...this.initData.considers];
              this.objectives.forEach((objective) => {
                objective.objectiveKeyResults = objective.objectiveKeyResults.map(
                  (keyResult) => {
                    return {
                      ...keyResult,
                    };
                  }
                );
              });
            }
          })
          .then(() => {
            this.loading = false;
          });
      },
  
      back() {
        this.$router.push({
          name: `dashboard`,
        });
      },
    },
    setup() {
      const fields = FormInput;
      return { fields };
    },
  };
  </script>
              