<template>
  <b-row class="match-height">
    <b-col>
     <b-card v-if="data.okrSetting != null">
       <profile-information
        :userId="data.okrSetting.employeeId"
      ></profile-information>
      <br />
      </b-card>
    </b-col>
    <b-col cols="12" v-if="!loading">
      <b-tab active title="Left">
        <b-tabs
          horizontal
          pills
          nav-wrapper-class="nav-vertical"
          nav-class="justify-content-start"
        >
          <b-tab active :title-item-class="'text-left'">
            <template #title>
              <feather-icon icon="FileIcon" />
              <span>{{ $t("general.okrSetting") }}</span>
            </template>
            <b-card class="mt-1">
              <b-card-text v-if="data.okrSetting != null">
                <update-okr-setting
                  v-if="data.okrSetting.state == State.APPROVED"
                  :okrSetting="data.okrSetting"
                ></update-okr-setting>
                <view-okr-setting
                  :okrSetting="data.okrSetting"
                  v-else
                ></view-okr-setting>
              </b-card-text>
            </b-card>
          </b-tab>
          <div v-if="data.okrSetting != null">
            <div v-if="data.okrSetting.midYearReview != null">
              <b-tab
                :disabled="
                  data.okrSetting.state != State.HRAPPROVED  ||
                  data.okrSetting.midYearReview.state == State.DRAFT
                    ? true
                    : false
                "
              >
                <template #title>
                  <feather-icon icon="FileMinusIcon" />
                  <span>{{ $t("general.midYearReview") }}</span>
                </template>
                <b-card class="mt-1">
                  <b-card-text>
                    <update-mid-year-review
                      v-if="
                        data.okrSetting.midYearReview.state == State.APPROVED
                      "
                      :okrSetting="data.okrSetting"
                    ></update-mid-year-review>
                    <view-mid-year-review
                      :okrSetting="data.okrSetting"
                      v-else
                    ></view-mid-year-review>
                  </b-card-text>
                </b-card>
              </b-tab>
            </div>
            <b-tab v-else disabled>
              <template #title>
                <feather-icon icon="FileMinusIcon" />
                <span>{{ $t("general.midYearReview") }}</span>
              </template>
            </b-tab>
          </div>
          <b-tab v-else disabled>
            <template #title>
              <feather-icon icon="FileMinusIcon" />
              <span>{{ $t("general.midYearReview") }}</span>
            </template>
          </b-tab>
  
          <div v-if="data.okrSetting != null">
            <div v-if="data.okrSetting.midYearReview != null">
              <div v-if="data.okrSetting.yearEndReview != null">
                <b-tab
                  :disabled="
                    data.okrSetting.midYearReview.state != State.HRAPPROVED ||
                    data.okrSetting.yearEndReview.state == State.DRAFT ||
                    data.okrSetting.yearEndReview.state == State.SUBMITTED
                      ? true
                      : false
                  "
                >
                  <template #title>
                    <feather-icon icon="FileTextIcon" />
                    <span>{{ $t("general.yearEndReview") }}</span>
                  </template>
              
                  <b-card class="mt-1">
                    <b-card-text v-if="data.okrSetting != null">
                      <update-year-end-review
                        v-if="
                          data.okrSetting.yearEndReview.state == State.APPROVED &&
                          data.okrSetting.yearEndReview.state != State.HODAPPROVED
                        "
                        :okrSetting="data.okrSetting"
                      ></update-year-end-review>
                      <view-year-end-review-hod
                        :okrSetting="data.okrSetting"
                        v-else
                      ></view-year-end-review-hod>
                    </b-card-text>
                  </b-card>
                </b-tab>
              </div>
              <b-tab v-else disabled>
                <template #title>
                  <feather-icon icon="FileTextIcon" />
                  <span>{{ $t("general.yearEndReview") }}</span>
                </template>
              </b-tab>
            </div>
            <b-tab v-else disabled>
              <template #title>
                <feather-icon icon="FileTextIcon" />
                <span>{{ $t("general.yearEndReview") }}</span>
              </template>
            </b-tab>
          </div>
          <b-tab v-else disabled>
            <template #title>
              <feather-icon icon="FileTextIcon" />
              <span>{{ $t("general.yearEndReview") }}</span>
            </template>
          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon icon="AlertCircleIcon" />
              <span>{{ $t("general.ratingScaleValue") }}</span>
            </template>
            <b-card class="mt-1">
              <b-card-text>
                <rating-scale-and-value></rating-scale-and-value>
              </b-card-text>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-tab>
    </b-col>
  </b-row>
</template>
  
  <script>
import BCardCode from "@core/components/b-card-code";
import { BTabs, BTab, BCardText, BCard, BRow, BCol } from "bootstrap-vue";
import { State } from "@/libs/helper";
import UpdateMidYearReview from "../midYearReview/UpdateMidYearReview.vue";
import UpdateYearEndReview from "../yearEndReview/UpdateYearEndReview.vue";
import RatingScaleAndValue from "@/views/ratingScaleAndValue/RatingScaleAndValue.vue";
import ViewOkrSetting from "@/views/okrSettingReview/ViewOkrSetting.vue";
import UpdateOkrSetting from "./UpdateOkrSetting.vue";
import ViewMidYearReview from "@/views/midYearReview/ViewMidYearReview.vue";
import ProfileInformation from "@/components/ProfileInformation.vue";
import Repository from "@/repositories/RepositoryFactory";
import ViewYearEndReviewHod from '../yearEndReview/ViewYearEndReviewHod.vue';

const OkrSettingRepository = Repository.get("okrSetting");
export default {
  components: {
    BRow,
    BCol,
    BCardCode,
    BCardText,
    BTabs,
    BTab,
    BCard,
    RatingScaleAndValue,
    UpdateOkrSetting,
    UpdateMidYearReview,
    UpdateYearEndReview,
    ViewOkrSetting,
    ViewMidYearReview,
    ProfileInformation,
    ViewYearEndReviewHod,
  },
  data() {
    return {
      loading: false,
      data: {},
      State,
    };
  },

  created() {
    this.showOkr();
  },
  methods: {
    showOkr() {
      this.loading = true;
      OkrSettingRepository.showOkrSetting(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = { ...data };
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  setup() {
    const resource = "reviewOkrSetting";
    const route = "review-okr-setting";

    return {
      resource,
      route,
    };
  },
};
</script>
  