<template>
  <div>
    <div class="pl-2 pr-2">
      <h5>{{ $t("general.stepForOkrReview") }}</h5>
      <b-card-text>
        <p v-html="getTranslate(info, 'okrReview', $i18n.locale)"></p>
      </b-card-text>
    </div>
    <div class="p-2">
      <validation-observer ref="createForm" #default="{ invalid }">
        <n-form-confirmation
          key="createForm"
          @submit="submit"
          :form="$refs.createForm"
          :disabled="invalid"
        >
          <n-input-view :fields="fields" v-model="data" :initValue="initData">
            <template #objectives>
              <b-tabs pills class="tab-card mb-2" v-model="tabIndex">
                <b-tab
                  v-for="(type, index) in objectiveTypes"
                  :key="`${type}-${index}`"
                  @click="getCurrentTab(type)"
                >
                  <template #title>
                    <span>
                      {{ getTranslate(type, "title", $i18n.locale) }}
                    </span>
                  </template>
                  <template>
                    <b-card-text
                      class="mb-2 mt-2"
                      v-if="objectiveType.descriptionEn"
                    >
                      <h5>
                        {{
                          getTranslate(
                            objectiveType,
                            "description",
                            $i18n.locale
                          )
                        }}
                      </h5>
                    </b-card-text>
                    <p v-else class="mt-3"></p>
                    <okr-setting-header />
                    <app-collapse accordion type="margin">
                      <app-collapse-item
                        :is-visible="true"
                        :title="`${item}-${index}`"
                        v-for="(item, index) in objectives.filter(
                          (item) => item.objectiveTypeId === objectiveType.id
                        )"
                        :key="`${item}-${index}`"
                        class="mb-1 mt-1"
                        :class="{ 'collapse-item-bg': index % 2 != 0 }"
                      >
                        <template #header>
                          <okr-setting-question
                            v-if="item.objectiveTypeId == 1"
                            :no="index + 1"
                            :question="item.title"
                            :weight="item.weight"
                          >
                          </okr-setting-question>
                          <okr-setting-question
                            v-else
                            :no="index + 1"
                            :weight="item.weight"
                          >
                            <template #question>
                              <div>
                                <h6>
                                  {{
                                    getTranslate(
                                      item.objective,
                                      "description",
                                      $i18n.locale
                                    )
                                  }}
                                </h6>
                                <p>
                                  {{
                                    getTranslate(
                                      item.objective,
                                      "title",
                                      $i18n.locale
                                    )
                                  }}
                                </p>
                              </div>
                            </template>
                          </okr-setting-question>
                        </template>
                        <hr />
                        <okr-setting-answer>
                          <template #keyresult>
                            <div
                              v-for="(result, idx) in item.objectiveKeyResults"
                              :key="`${result}-${idx}`"
                              :value="result.title"
                            >
                              <p v-if="result.title != null">
                                {{ idx + 1 }}. {{ result.title }}
                              </p>
                            </div>
                          </template>
                        </okr-setting-answer>
                      </app-collapse-item>
                    </app-collapse>
                    <okr-setting-footer
                      :total-weight="calculateWeight(objectiveType.id)"
                    />
                  </template>
                </b-tab>
              </b-tabs>
            </template>
          </n-input-view>
          <div
            class="w-100 text-right"
            v-if="tabIndex == objectiveTypes.length - 1"
            v-show="initData.state == State.APPROVED"
          >
            <b-button
              v-b-modal.okr-setting-reject-modal
              variant="danger"
              :disabled="loading"
              class="mt-1 mr-2"
              ><b-spinner small label="Spinning" v-if="loading"></b-spinner>
              {{ $t("button.reject") }}</b-button
            >
            <b-modal
              ref="okr-reject-modal"
              id="okr-setting-reject-modal"
              centered
              size="lg"
              :title="$t('field.reason')"
              ok-title="Reject"
              ok-variant="danger"
              cancel-variant="outline-secondary"
            >
              <validation-provider
                #default="{ errors }"
                :vid="data.hodRejectRemark"
                :name="$t('field.reason')"
                rules="required"
              >
                <b-form-textarea
                  v-model="data.hodRejectRemark"
                  class="mb-2 mt-2"
                  rows="3"
                  :placeholder="$t('field.rejectOkrPlaceHolder')"
                  :name="data.hodRejectRemark"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <template #modal-footer>
                <b-button
                  @click="hideModal"
                  class="btn btn-sm"
                  variant="outline-secondary"
                  >{{ $t("button.cancel") }}</b-button
                >
                <n-button-reject
                  ref="btnReject"
                  @submit="submit(State.REJECTED)"
                  :loading="loading"
                ></n-button-reject>
              </template>
            </b-modal>
            <n-button-approve
              ref="btnSubmit"
              @submit="submit(State.APPROVED)"
              @save="save"
              :loading="loading"
            ></n-button-approve>
            <n-button-loading
              type="button"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :loading="loading"
              class="mt-1"
              @submit="back"
            >
              {{ $t("button.back") }}
            </n-button-loading>
          </div>
        </n-form-confirmation>
      </validation-observer>
    </div>
  </div>
</template>
  
  <script>
import {
  BCol,
  BRow,
  BCard,
  BFormRadioGroup,
  BFormRadio,
  BFormGroup,
  BCardText,
  BFormInput,
  BTabs,
  BTab,
  BButton,
  BFormTextarea,
  BInputGroup,
  BModal,
  BSpinner,
  BForm,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import BCardCode from "@core/components/b-card-code";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Repository from "@/repositories/RepositoryFactory";
import { getTranslate,State } from "@/libs/helper";
import OkrSettingQuestion from "@/components/OkrSettingQuestion.vue";
import OkrSettingAnswer from "@/components/OkrSettingAnswer.vue";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import NInputView from "@/components/NInputView";
import NButtonApprove from "@/components/NButtonApprove.vue";
import NButtonReject from "@/components/NButtonReject.vue";
import NButtonSubmit from "@/components/NButtonSubmit.vue";
import OkrSettingHeader from "@/components/OkrSettingHeader.vue";
import OkrSettingFooter from "@/components/OkrSettingFooter.vue";
import FormInput from "./formInput";
import ProfileInformation from "@/components/ProfileInformation.vue";
const OkrSettingRepository = Repository.get("okrSetting");
const ObjectiveTypeRepository = Repository.get("objectiveType");
const StepInformationRepository = Repository.get("stepInformation");
export default {
  components: {
    BFormInput,
    BCard,
    BCol,
    BRow,
    BFormRadioGroup,
    BFormRadio,
    BCardText,
    BFormGroup,
    BButton,
    BFormTextarea,
    BInputGroup,
    BTabs,
    BTab,
    BModal,
    BSpinner,
    BForm,
    BCardCode,
    AppCollapse,
    AppCollapseItem,
    OkrSettingAnswer,
    OkrSettingQuestion,
    NInput,
    NButtonLoading,
    NFormConfirmation,
    NButtonApprove,
    NButtonReject,
    NButtonSubmit,
    OkrSettingHeader,
    OkrSettingFooter,
    NInputView,
    ProfileInformation,
  },
  directives: {
    Ripple,
  },
  props: {
    okrSetting: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tabIndex: 0,
      info: {},
      data: {
        employeeId: null,
        year: null,
        state: null,
        hodRejectRemark: null,
        hodApproveBy: null,
        hodRejectBy: null,
        objectives: [],
      },
      initData: {},
      objectiveType: {},
      objectiveTypes: [],
      objectives: [],
      totalWeight: 0,
      loading: false,
      isValidate: true,
      user: JSON.parse(localStorage.getItem("userData")),
      query: {
        page: Number(this.$route.query.page) || 1,
        order: "sequence_order",
        sort: "ASC",
      },
      getTranslate,
      State
    };
  },
  created() {
    this.getStepInformation();
    this.getObjectiveType();
    this.show();
  },
  methods: {
    getStepInformation() {
      this.loading = true;
      StepInformationRepository.show()
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.info = data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getCurrentTab(objectiveType) {
      this.objectiveType = objectiveType;
    },
    getObjectiveType() {
      this.loading = true;
      const params = {
        ...this.query,
      };
      ObjectiveTypeRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.objectiveTypes = [...data.list];

            if (this.objectiveTypes.length > 0) {
              this.objectiveType = this.objectiveTypes[0];
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    calculateWeight(objectiveTypeId) {
      var total = 0;
      this.objectives.forEach((item) => {
        if (item.objectiveTypeId == objectiveTypeId) {
          total += Number(item.weight);
        }
      });

      return total;
    },

    hideModal() {
      this.$refs["okr-reject-modal"].hide();
    },

    show() {
      this.loading = true;
      const params = `${this.okrSetting.id}`;
      OkrSettingRepository.show(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.initData = {
              ...data,
            };
            this.objectives = this.initData.objectives;
          }
        })
        .then(() => {
          this.loading = false;
        });
    },

    submit(type) {
      this.$refs.createForm.validate().then((success) => {
        if (success) {
          if (type == State.APPROVED) {
            this.$refs.btnSubmit.confirm(type);
          } else {
            this.save();
            this.$refs["okr-reject-modal"].hide();
          }
        }
      });
    },

    save(type) {
      this.loading = true;
      if (type == State.APPROVED) {
        this.data.state = State.HODAPPROVED;
        this.data.hodApproveBy = this.user.id;
      } else {
        this.data.state = State.HODREJECTED;
        this.data.hodRejectBy = this.user.id;
      }
      this.data.objectives = [...this.objectives];
      OkrSettingRepository.update(this.okrSetting.id, this.data)
        .then((response) => {
          this.$refs.btnSubmit.afterSave(type, response.data.data.id);
        })
        .catch((error) => {
          console.log(error);
          if (error.response?.status == 422) {
            this.$refs.createForm.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push({
        name: `dashboard`,
      });
    },
  },
  setup() {
    const fields = FormInput;
    return { fields };
  },
};
</script>
  